import * as React from "react";
import { useWeb3Provider } from "../../contexts/AppContext";
import logoThePolacy from "../../images/logo-polacy.svg";
import {
  ETH_CHAIN_ID,
  isEthereumNetworkChosen,
  trimAddress,
} from "../../utils";
import { Link } from "gatsby";
import { Anchor } from "../Items/NavAnchor";
import "./NavBar.scss";
import { BurgerSVG, CloseSVG } from "../Svgs";
import { AnnounceMarquee } from "../AnnounceMarquee/AnnonuceMarquee";
import { Tooltip } from "react-tooltip";

export const NavBar = ({ serverData }: any) => {
  const webProvider = useWeb3Provider();
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  const [stickyClass, setStickyClass] = React.useState("relative");

  const [currentActive, setCurrentActive] = React.useState("");

  React.useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100
        ? setStickyClass("stick-navbar__scrolled stick-navbar")
        : setStickyClass("");
    }
  };

  return (
    <>
      {" "}
      <AnnounceMarquee serverData={serverData} />
      <div className="navbar mobile-menu-container">
        <Link to="/" className="logo-container navbar__logo-box">
          <img
            className={showMobileNav ? "navbar__hidden" : ""}
            src={logoThePolacy}
            alt="ThePolacyNFT logo"
          />
        </Link>
        <button
          className={`navbar__button mobile-menu-button ${
            showMobileNav ? "navbar__hidden" : ""
          }`}
          onClick={() => setShowMobileNav(true)}
        >
          <BurgerSVG />
        </button>
      </div>
      <nav
        className={`mobile-nav-container ${
          showMobileNav ? "" : "navbar__hidden"
        }`}
      >
        <button
          className="close-nav-btn"
          onClick={() => setShowMobileNav(false)}
        >
          <CloseSVG />
        </button>
        <Link
          to="/"
          className="mobile-menu-open-logo"
          onClick={() => setShowMobileNav(false)}
        >
          <img className="" src={logoThePolacy} alt="ThePolacyNFT logo" />
        </Link>
        <Anchor
          id="sasiedzi"
          title="Sąsiedzi"
          // onClickAction={() => setShowMobileNav(false)}
        />
        <Anchor id="drops" title="Dropy" />
        <Anchor id="memes" title="Memy" />
        <Anchor id="timetable" title="Wydarzenia" />
        <Anchor id="merch" title="Merch" />
        <Anchor id="toppolacy" title="TopPolacy" />
        <Anchor id="blog" title="Blog" />
        <a
          className={`red-btn btn-clicked ${
            !isEthereumNetworkChosen(webProvider.evmNetworkChosen)
              ? "opacity-50"
              : ""
          }`}
          href="#"
          onClick={async () => {
            // await webProvider.connectWeb3Wallet();
            await webProvider.handleLogin();
          }}
        >
          {webProvider.accountDetails?.address !== undefined
            ? `${trimAddress(webProvider.accountDetails.address)}`
            : "Połącz"}
        </a>
      </nav>
      <section
        className={`${stickyClass} navbar__section desktop-nav-container`}
      >
        <div className="navbar__top-menu-box nav-container" id="navbar-default">
          <nav className="navbar__left-navigation">
            <Anchor
              id="sasiedzi"
              title="Sąsiedzi"
              onClickAction={() => {
                setCurrentActive("sasiedzi");
              }}
              isActive={currentActive === "sasiedzi"}
            />
            <Anchor
              id="drops"
              title="Dropy"
              onClickAction={() => {
                setCurrentActive("drops");
              }}
              isActive={currentActive === "drops"}
            />
            <Anchor
              id="memes"
              title="Memy"
              onClickAction={() => {
                setCurrentActive("memes");
              }}
              isActive={currentActive === "memes"}
            />
            <Anchor
              id="timetable"
              title="Wydarzenia"
              onClickAction={() => {
                setCurrentActive("timetable");
              }}
              isActive={currentActive === "timetable"}
            />
          </nav>
          <Link to="/">
            <div className="logo-container">
              <img src={logoThePolacy} alt="ThePolacyNFT logo" />
            </div>
          </Link>
          <nav className="navbar__right-navigation">
            <Anchor
              id="merch"
              title="Merch"
              onClickAction={() => {
                setCurrentActive("merch");
              }}
              isActive={currentActive === "merch"}
            />
            <Anchor
              id="toppolacy"
              title="TopPolacy"
              onClickAction={() => {
                setCurrentActive("toppolacy");
              }}
              isActive={currentActive === "toppolacy"}
            />
            <Anchor
              id="blog"
              title="Blog"
              onClickAction={() => {
                setCurrentActive("blog");
              }}
              isActive={currentActive === "blog"}
            />
            <a
              className={`red-btn btn-clicked ${
                !isEthereumNetworkChosen(webProvider.evmNetworkChosen)
                  ? "opacity-50"
                  : ""
              }`}
              data-tooltip-id={
                !isEthereumNetworkChosen(webProvider.evmNetworkChosen)
                  ? "wrong-network"
                  : ""
              }
              data-tooltip-content={
                !isEthereumNetworkChosen(webProvider.evmNetworkChosen)
                  ? "Wybierz Mainnet Ethereum i odśwież stronę 👀"
                  : ""
              }
              href="#"
              onClick={async () => {
                if (webProvider.accountDetails?.address !== undefined) {
                  await webProvider.handleLogout();
                } else {
                  if (!isEthereumNetworkChosen(webProvider.evmNetworkChosen)) {
                    return;
                  } else {
                    await webProvider.handleLogin();
                  }
                }
              }}
            >
              {webProvider.accountDetails?.address !== undefined
                ? `${trimAddress(webProvider.accountDetails.address)}`
                : "Połącz"}
            </a>
            <Tooltip id="wrong-network" place="bottom" variant="dark" />
          </nav>
        </div>
      </section>
    </>
  );
};
