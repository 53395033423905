import * as React from "react";
import type { HeadFC } from "gatsby";
import { useMediaQuery } from "react-responsive";

import { useWeb3Provider, Web3Providers } from "../contexts/AppContext";

import mainTimetableImage from "../images/main-timetable-header-bus-2.png";

import { NavBar } from "../components/NavBar/NavBar";

import { PageHeader } from "../components/PageHeader/PageHeader";
import { TimetableItem } from "../components/TimetableItem/TimetableItem";

import { Timetable, TimetableType } from "../services/Timetable";
import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import { useEffect, useState } from "react";
import { Loader } from "../components/Loader";
import { DashboardNavbar } from "../components/DashboardNavbar/DashboardNavbar";
import { SEO } from "../components/SEO";

const IndexPage = ({ serverData }: { serverData: TimetableType[] }) => {
  return (
    <Web3Providers>
      <IndexPageComponent serverData={serverData} />
    </Web3Providers>
  );
};
const IndexPageComponent = ({
  serverData,
}: {
  serverData: TimetableType[];
}) => {
  const web3Provider = useWeb3Provider();
  const [timetableList, setTimetableList] =
    React.useState<TimetableType[]>(serverData);
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: "1350px" });

  React.useEffect(() => {
    // setIsLoading(true);
    // Timetable.getTimetableEvents()
    //   .then(res => {
    //     if (res instanceof Error) throw res;
    //     setTimetableList(res);
    //     setIsLoading(false);
    //   })
    //   .catch(err => console.log("handle the error"));
  }, []);

  return (
    <Web3Providers>
      <main className="timetable-page">
        {web3Provider.accountDetails?.address ? (
          <DashboardNavbar />
        ) : (
          <NavBar />
        )}
        <PageHeader
          title="Jaki rozkład jazdy?"
          subtitle="ThePolacy to istoty stadne, dlatego regularne spotkania są istotą naszej społeczności. Wspólne biesiadowanie, opowiadanie o ostatnim gemie 100x czy dzielenie się najgorszymi rug pullowymi historami to coś co nas spaja i tworzy charakter. 
          Nie zamulaj i wpadnij na wspólny melanż byś, cytując klasyka, mógł “przeżyć to sam”. "
          additionalClass="timetable-view__header"
        />

        {isLoading && <Loader />}

        <section className="timetable-view__section">
          <div className="timetable-view__box">
            {timetableList.length > 0 && (
              <>
                <TimetableItem
                  isClosed={timetableList[0].Status ?? false}
                  image={timetableList[0].ImageUrl ?? mainTimetableImage}
                  city={timetableList[0].City}
                  date={timetableList[0].Date}
                  time={timetableList[0].Time}
                  place={timetableList[0].Place}
                  title={timetableList[0].Title}
                  description={timetableList[0].Description}
                  actionUrl={""}
                  isMainTimetableItem={!isSmallScreen}
                  className={
                    isSmallScreen
                      ? ""
                      : "timetable-section__main-item timetable-main-item"
                  }
                  eventId={0}
                  isStillReceivingTickets={
                    timetableList[0].StillReceivingTickets ?? true
                  }
                  address={web3Provider.accountDetails?.address}
                  TicketReceivingActive={timetableList[0].TicketReceivingActive}
                />

                <div className="timetable-view__one-box">
                  {timetableList.length > 0 &&
                    timetableList?.map((item, index) => {
                      if (index === 0) return;
                      return (
                        <TimetableItem
                          isClosed={item.Status ?? false}
                          image={item.ImageUrl}
                          city={item.City}
                          date={item.Date}
                          time={item.Time}
                          place={item.Place}
                          title={item.Title}
                          description={item.Description}
                          actionUrl={""}
                          isMainTimetableItem={false}
                          eventId={item.id ?? -1}
                          isStillReceivingTickets={
                            timetableList[0].StillReceivingTickets ?? true
                          }
                          address={web3Provider.accountDetails?.address}
                          TicketReceivingActive={item.TicketReceivingActive}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </div>
          <GoHomeButton />
        </section>
      </main>
    </Web3Providers>
  );
};

export default IndexPage;

// export const Head: HeadFC = () => <title>Rozkład jazdy</title>;
export const Head: HeadFC = () => <SEO subtitle="Rozkład jazdy" />;

export async function getServerData() {
  try {
    const timetableResponse = await Timetable.getTimetableEvents();
    if (timetableResponse instanceof Error) throw timetableResponse;
    return {
      props: timetableResponse,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
}
