import * as React from "react";
import "./PageHeader.scss";

interface IPageHeader {
  title: string;
  subtitle: string;
  additionalClass?: string;
}

export const PageHeader = ({
  title,
  subtitle,
  additionalClass,
}: IPageHeader) => {
  return (
    <header className={`page-header ${additionalClass ?? ""}`}>
      <div className="page-header-content radius theshadow">
        <h2 className="page-header-box__header">{title}</h2>
        <p>{subtitle}</p>
      </div>
    </header>
  );
};
